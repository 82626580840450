/* eslint-disable react/prop-types */
import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as PubCookie } from '../../images/icons/pug_cookie.svg';

import styles from './Gdpr.module.scss';
import { useCookies } from 'react-cookie';
import loadable from '@loadable/component';

const Button = loadable(() => import('../Button/Button'));

const COOKIE_NAME = 'TT_cookies_allowed';

const Gdpr = ({ config, intl }) => {
  const [cookies, setCookie] = useCookies([COOKIE_NAME]);
  const cookiesAllowed = cookies[COOKIE_NAME];

  const cookieExpirationDate = new Date();
  const cookieDomain = config.baseUrl
    .replace(/^https?:\/\//gi, '')
    .split(':')
    .shift();

  cookieExpirationDate.setFullYear(cookieExpirationDate.getFullYear() + 1);

  if (cookiesAllowed) {
    return null;
  }

  const grantUserConsent = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('consent', 'update', {
      ad_user_data: 'granted',
      ad_personalization: 'granted',
      ad_storage: 'granted',
      analytics_storage: 'granted',
    });
    setCookie(COOKIE_NAME, 1, {
      domain: cookieDomain,
      expires: cookieExpirationDate,
      path: '/',
    });
  };

  return (
    <div className={styles.gdpr} data-testid="gdpr">
      <div className="container">
        <div className="row">
          <div className={classNames(styles.gdprContent, 'col-12-xs')}>
            <PubCookie />
            <div className={styles.gdprContentText}>
              <FormattedMessage
                id="common.cookie.message"
                values={{ webShopName: config.websiteName }}
                defaultMessage="We use cookies to improve our site and your shopping experience. By continuing to browse our site, you are accepting our cookie policy."
              />
              {/* todo: ged rid of all usage of intl.messages. You should use react-intl library instead */}
              {intl.messages['common.cookie.linkText'] && (
                <>
                  {' '}
                  <Link to={`/${config.cmsSlugs.cookies}/#cookies`}>
                    <FormattedMessage id="common.cookie.linkText" defaultMessage="" />
                  </Link>{' '}
                </>
              )}
              {intl.messages['common.cookie.messageEnd'] && (
                <FormattedMessage id="common.cookie.messageEnd" defaultMessage="" />
              )}
            </div>
          </div>
          <Button
            classList={{ root: classNames(styles.accept, 'col-12-xs', 'col-6-xl') }}
            onClick={grantUserConsent}
            variation={'secondary'}
            data-testid="gdprSetCookie"
          >
            <FormattedMessage id="common.cookie.accept" defaultMessage="Accept" />
          </Button>
          <Link className={classNames(styles.noLine, 'col-12-xs', 'col-6-xl')} to={`/${config.cmsSlugs.privacy}/`}>
            <Button inverted variation={'secondary'}>
              <FormattedMessage id="common.cookie.link" defaultMessage="Find out more" />
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

Gdpr.displayName = 'Gdpr';

export const mapStatesToProp = ({ config, intl }) => ({ config, intl });
export default connect(mapStatesToProp)(Gdpr);
